import React  from 'react';
import logo from '../../data/appData/appImages/entete/logo150px.png'
import './appHeaderCss.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/fontawesome-free-solid';

function Header(){

    return(





    <div id='bandeauId' class="bandeauClassResp">

        <div id='logoDiv' class="logoDivClassResp">
            <img id='logoImageId'   src={logo} alt='pizzeria Reims' /> 
        </div>
	
        <div id='teleAdrDiv' class="teleAdrDivClassResp">
            
            <div class="adresseDiv adresseDivResp"> &nbsp;<FontAwesomeIcon icon={faMapMarker} /> 60 rue Clovis Chezel, Reims</div>

        </div>

    </div>

    );
}
export default Header