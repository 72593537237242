import React from "react";
import CommandLink from '../commandLink/CommandLink'
import MenuItem from '../menuItem/MenuItem'
import './appWelcomeCss.css' ;
import formuleLogo from '../../data/appData/appImages/carteCatalogue/230px/formule.png'
import pizzaTLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_t.png'
import pizzaCLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_cf.png'
import assietteLogo from '../../data/appData/appImages/carteCatalogue/230px/assiette.png'
import tacosLogo from '../../data/appData/appImages/carteCatalogue/230px/tacos2.png'
import sandwichLogo from '../../data/appData/appImages/carteCatalogue/230px/sandwich.png'
import texMexLogo from '../../data/appData/appImages/carteCatalogue/230px/texMex.png'
import saladeLogo from '../../data/appData/appImages/carteCatalogue/230px/salade.png'
import dessertLogo from '../../data/appData/appImages/carteCatalogue/230px/dessert.png'
import boissonLogo from '../../data/appData/appImages/carteCatalogue/230px/boisson.png'
import enfantLogo from '../../data/appData/appImages/carteCatalogue/230px/enfant.png'
import asiatiqueLogo from '../../data/appData/appImages/carteCatalogue/230px/asiatique.png'


function Content(){


    const menuItemTable =[
        [formuleLogo, "https://www.walima-eat.com/#/etablissements/51100/2/site/category1", "NOS FORMULES"],
        [asiatiqueLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category14',"NOS ASIATIQUES"],
        [pizzaTLogo, "https://www.walima-eat.com/#/etablissements/51100/2/site/category2", "NOS PIZZAS T"],
        [pizzaCLogo, "https://www.walima-eat.com/#/etablissements/51100/2/site/category3", "NOS PIZZAS C F"],
        [assietteLogo, "https://www.walima-eat.com/#/etablissements/51100/2/site/category4", "NOS PLATS"],
        [tacosLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category5', "NOS TACOS"],
        [sandwichLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category6', "NOS SANDWICHS"],
        [texMexLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category9', "NOS TEXMEX"],
        [enfantLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category11',"NOS MENUS ENFANT"],
        [saladeLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category10',"NOS SALADES"],
        [dessertLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category12',"NOS DESSERTS"],
        [boissonLogo, 'https://www.walima-eat.com/#/etablissements/51100/2/site/category13',"NOS BOISSONS"],
        
    ];
    return(

        <div className='content1Div'>
            <CommandLink></CommandLink>

            <div className="centePerCentWidthAndFloatContainer">
                <div className='rowContainer rowContainerResp'>
                    
                    <div>
                        {menuItemTable.map((item, index) => {
                                return (
                                    <MenuItem menuItemLogo={item[0]}  menuItemLink={item[1]} itemTitle={item[2]} key={index}></MenuItem>
                                );

                            })}
                    </div>
                
                </div>
            </div>
            

        </div>

    );
}

export default Content