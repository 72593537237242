import React from "react";
import './appFooterCss.css'
import fbImage from '../../data/appData/appImages/rs/fb.png'
import googleImage from '../../data/appData/appImages/rs/gp.png'
import twiterImage from '../../data/appData/appImages/rs/tw.png'
import youtubeImage from '../../data/appData/appImages/rs/yt.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faCopyright} from '@fortawesome/fontawesome-free-solid'







function Footer(){
    return(



        <div id='footer1Id'>

	<div id='ConnectionDiv'>
		{/*
		<div id = "connexionDivMarg">
            
			
			<br/>
			
			
 				<a href="appLogin.arm?action=login" ><i class="fas fa-tools"></i><br/>Admin</a>
			
			
			
 				<a href="appLogin.arm?action=welcome" ><i class="fas fa-user-circle"></i><br/>Compte</a>
    
		</div>	
        */}
	</div>
	
	
	
	<div className="footerMenuDiv"> 
			<div className="footerOneMenuDiv footerOneMenuDivResp">
				<div className="footerOneMenuDivMarge"> <a href='/'>Accueil</a></div>
			</div>
			<div className="footerOneMenuDiv footerOneMenuDivResp">
				<div className="footerOneMenuDivMarge"> <a href='/'>Evaluation</a></div>
			</div>
			<div className="footerOneMenuDiv footerOneMenuDivResp">
				<div className="footerOneMenuDivMarge"> <a href='/'>Contact</a></div>
			</div>
			<div className="footerOneMenuDiv footerOneMenuDivResp">
				<div className="footerOneMenuDivMarge"> <a href='/localisation'>Localisation</a></div>
			</div>
		
		 </div>
	
	
	
	
	
	<div id='rsDiv'>
		<br/><br/><br/><br/><br/>
		<a href='https://fr-fr.facebook.com/restaurant.TACOS51' ><img src={fbImage} alt='kebab reims' /></a> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
		<a href='https://goo.gl/maps/Yxfp8NUM4fvNr4wp8' ><img src={googleImage} alt='pizza reims'/></a> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
		<a href='https://fr-fr.facebook.com/restaurant.TACOS51'  ><img src={twiterImage} alt='transporter 51'/></a> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
		<a href='https://fr-fr.facebook.com/restaurant.TACOS51' ><img src={youtubeImage} alt='Navette aéroport' /></a>
		<br/><br/>
		
	</div>	
	
	<span id='refDiv'>
		<a href="http://www.adansy.com" >-</a>

		<a href="http://www.aux-delices-de-sainte-anne.fr" >-</a>

		<a href="http://www.bagagemaroc.com" >-</a>

		<a href="http://www.casa-resto.fr" >-</a>

		<a href="http://www.controleautomobile.fr" >-</a>

		<a href="http://www.eat-crepes.fr" >-</a>

		<a href="http://www.halal-eat.com" >-</a>

		<a href="http://www.pizzahalal.fr" >-</a>

		<a href="http://www.reims-vtc.fr" >-</a>

		<a href="http://www.restopizza.fr" >-</a>

		<a href="http://www.royalfastfood-pommery.fr" >-</a>

		<a href="http://www.royalfastfood-reims.com" >-</a>

		<a href="http://www.royalfastfood-reims.fr" >-</a>

		<a href="http://www.royalfastfood.fr" >-</a>
		
		<a href="http://www.sofood-sogood.net" >-</a>

		<a href="http://www.tacos51.fr" >-</a>
		
		
		<a href="http://www.walima-eat.com" >-</a>

		<a href="http://www.walima-eat.fr" >-</a>
	
	
	
	</span>


    <div className="copyrightDiv" > 
		<a href="http://www.adansy.com" ><FontAwesomeIcon icon={faCompass} /> Designed by ADANSY </a> &nbsp; &nbsp; &nbsp; &nbsp; 
		<FontAwesomeIcon icon={faCopyright} /> 2020 Tacos51
		<br/><br/><br/><br/><br/><br/>
	</div>

</div>




    );
}

export default Footer
